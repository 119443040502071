import React from 'react';
import axios from 'axios';
import { withRouter } from '../../common/with-router';
import { Link } from 'react-router-dom';
import apiUrl from '../../config.js';

class ReportView extends React.Component {
  constructor(props) {
    super(props);
    const today = new Date().toISOString().split('T')[0];
    this.state = {
        startDate: today,
        endDate: today,
      reportData: [],
      error: null,
    };
  }

  handleStartDateChange = (event) => {
    const startDate = event.target.value;
    this.setState({
      startDate,
    });
  };

  handleEndDateChange = (event) => {
    const endDate = event.target.value;
    this.setState({
      endDate,
    });
  };



  handleViewReport = async (event) => {
    event.preventDefault();
  
    const { startDate, endDate } = this.state;
  
    try {
      const response = await axios.get(`${apiUrl}/api/report?startDate=${startDate}&endDate=${endDate}`);
  
      // Open the report in a new tab
      const newTab = window.open('', '_blank');
      newTab.document.write(response.data);
  
      this.setState({ reportData: response.data, error: null });
    } catch (error) {
      this.setState({ reportData: [], error: error.message });
    }
  };
  

  

  render() {
    const { startDate, endDate, reportData, error } = this.state;

    return (
      <div className="container-fluid pt-4 px-4">
        <div className="row g-4">
          <div className="col-sm-6 col-xl-6">
            <div className="bg-light rounded h-100 p-4">
              <h6>View Report</h6>
              <Link className="btn btn-primary btn-sm" to="/appointment/list">
                <i className="fas fa-list"></i> View All Appointments
              </Link>
              <form onSubmit={this.handleViewReport}>
                <div className="form-group">
                  <label>Start Date:</label>
                  <input
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={this.handleStartDateChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>End Date:</label>
                  <input
                    type="date"
                    name="endDate"
                    value={endDate}
                    onChange={this.handleEndDateChange}
                    className="form-control"
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary mt-2">
                  View Report
                </button>
              </form>              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ReportView);
