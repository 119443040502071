import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import { withRouter } from "../../common/with-router";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class UpdateDepartment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      error: null,
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const id = this.props.params.id;
    axios
      .get(`http://localhost:8000/api/departments/${id}`)
      .then((response) => {
        const data = response.data;
        this.setState({
          name: data.name,
          description: data.description,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { name, description } = this.state;

    const postData = {
      name,
      description,
    };

    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const config = {
        headers: { Authorization: `Bearer ${user.authorisation.token}` },
      };
      const id = this.props.params.id;
      const response = await axios.put(
        `http://localhost:8000/api/departments/${id}`,
        postData,
        config
      );
      this.props.router.navigate("/department/list");
      Swal.fire({
        icon: "success",
        title: "Department updated successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      console.log("Department updated:", response.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error updating department",
        text: error.response.data.message,
        confirmButtonText: "OK",
      });
      console.error("Error updating department:", error);
    }
  };

  render() {
    const { name, description } = this.state;
    return (
      <div className="container-fluid pt-4 px-4">
        <div className="row g-4">
          <div className="col-sm-6 col-xl-6">
            <div className="bg-light rounded h-100 p-4">
              <h2>Edit Department</h2>
              <Link className="btn btn-primary btn-sm" to={"/department/list"}>
                <i className="far fa-address-card"></i> Departments
              </Link>

              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.handleInputChange}
                    className="form-control form-control-sm"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Description:</label>
                  <textarea
                    name="description"
                    value={description}
                    onChange={this.handleInputChange}
                    className="form-control"
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-sm btn-primary mt-2">
                  Edit Department
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withParams(UpdateDepartment));
