import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import apiUrl from '../config.js';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: null,
      error: null,
      appointments: [],
      isLoading: true,
      currentPage: 1,
      itemsPerPage: 10,
      user: JSON.parse(localStorage.getItem('user')).user
    };
  }
  componentDidMount() {
    this.getAppointments();
  }
  getAppointments = () => {
    axios
      .get(`${apiUrl}/api/appointments`)
      .then((response) => {
        this.setState({ appointments: response.data, isLoading: false });
      })
      .catch((error) => {
        this.setState({
          error: error,
          isLoading: false,
        });
      });
  };
  handleDelete = (id) => {
    // Remaining code for handling deletion
  };
  paginate = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };
  render() {
    const { count, user } = this.state;
    const { isLoading, error, currentPage, itemsPerPage } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = this.state.appointments.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    if (isLoading) {
      return <p>Loading...</p>;
    }

    if (error) {
      return <p>Error: {error.message}</p>;
    }

    return (
      <div className="container-fluid pt-4 px-4">

        {user.role == 1 || user.role == 2 ? (
          <div className="row g-4">
            <div className="col-sm-6 col-xl-3">
              <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                <i className="fa fa-chart-line fa-3x text-primary"></i>
                <div className="ms-3">
                  <Link to="/appointment/list">
                    <p className="mb-2">Appointments</p>
                    <h6 className="mb-0">{count && count.appointment}</h6>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3">
              <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                <i className="fa fa-procedures fa-3x text-primary"></i>
                <div className="ms-3">
                  <Link to="/clinic/list">
                    <p className="mb-2">Clinics</p>
                    <h6 className="mb-0">{count && count.clinic}</h6>
                  </Link>
                </div>
              </div>
            </div>           
            <div className="col-sm-6 col-xl-3">
              <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                <i className="fa fa-user-md fa-3x text-primary"></i>
                <div className="ms-3">
                  <Link to="/doctor/list">
                    <p className="mb-2">Doctors</p>
                    <h6 className="mb-0">{count && count.doctor}</h6>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3">
              <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                <i className="fa fa-user fa-3x text-primary"></i>
                <div className="ms-3">
                  <Link to="/patient/list">
                    <p className="mb-2">Patients</p>
                    <h6 className="mb-0">{count && count.patient}</h6>
                  </Link>
                </div>
              </div>
            </div>       
          </div>

        ) :
          <div></div>
        }
        <br />
        <div className="row g-4">
          <div className="col-sm-12">
            <div className="bg-light rounded h-100 p-4">
              <h6 className="mb-4">List of appointments</h6>
              <Link className="btn btn-primary btn-sm" to={"/appointment/add"}>
                <i className="far fa-address-card"></i> Add Appointments
              </Link>
              <table className="table table-striped">
                <thead>
                  <tr>
                  <th>Action</th>
                    <th>ID</th>                    
                    <th>Date</th>
                    <th>Time</th>
                    <th>Clinic Name</th>
                    <th>Doctor Name</th>
                    <th>Patient Name</th>
                  </tr>
                </thead>
                <tbody>
                    {currentItems.map((appointment) => (
                      <tr key={appointment.id}>
                        <td scope="row">
                          <Link
                            title="Edit"
                            to={`/appointment/edit/${appointment.id}`}
                          >
                            <i className="far fa-edit"></i>
                          </Link>
                          &nbsp;
                          <a
                            className="text-danger"
                            title="Delete"
                            onClick={() => this.handleDelete(appointment.id)}
                          >
                            <i className="far fa-trash-alt"></i>
                          </a>
                        </td>
                        <td>{appointment.id}</td>
                        <td>{appointment.appointment_date}</td>
                        <td>
                          {appointment.appointment_time !== "00:00"
                            ? appointment.appointment_time
                            : "Time not specified"}
                        </td>
                        <td>
                          {appointment.clinic.id !== 1
                            ? appointment.clinic.name
                            : "Null"}
                        </td>
                        <td>
                          {appointment.doctor.id !== 1
                            ? appointment.doctor.name
                            : "Null"}
                        </td>
                        <td>
                          {appointment.patient.surname}{" "}
                          {appointment.patient.firstname}
                        </td>
                      </tr>
                    ))}
                  </tbody>
              </table>

              <nav>
                <ul className="pagination">
                  {Array.from({
                    length: Math.ceil(
                      this.state.appointments.length / itemsPerPage
                    ),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${currentPage === index + 1 ? "active" : ""
                        }`}
                    >
                      <a
                        className="page-link"
                        onClick={() => this.paginate(index + 1)}
                      >
                        {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
