import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { withRouter } from '../../common/with-router';
import { Link} from 'react-router-dom';
import apiUrl from "../../config";

class CreatePatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surname: '',
      firstname: '',
      date_of_birth: '',
      address: '',
      email: '',
      work_number: '',
      home_number: '',
      cell_number: '',
      medicalaidname: '',
      medicalaidnumber: '',
      medical_history: '',
      error: null,
      currentUser: JSON.parse(localStorage.getItem('user')).user,
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const {
      surname,
      firstname,
      date_of_birth,
      address,
      email,
      work_number,
      home_number,
      cell_number,
      medicalaidname,
      medicalaidnumber,
      medical_history,
      currentUser,
    } = this.state;

    const postData = {
      surname,
      firstname,
      date_of_birth,
      address,
      email,
      work_number,
      home_number,
      cell_number,
      medicalaidname,
      medicalaidnumber,
      medical_history,
      user_id: currentUser.id,
    };

    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const config = {
        headers: { Authorization: `Bearer ${user.authorisation.token}` }
      };
      const response = await axios.post(`${apiUrl}/api/patients`, postData, config);
      this.props.router.navigate("/patient/list");

      Swal.fire({
        icon: 'success',
        title: 'Patient created successfully',
        showConfirmButton: false,
        timer: 1500
      });
      console.log('Patient created:', response.data);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error creating patient',
        text: error.response.data.message,
        confirmButtonText: 'OK'
      });
      console.error('Error creating patient:', error);
    }
  };

  render() {
    const {
      surname,
      firstname,
      date_of_birth,
      address,
      email,
      work_number,
      home_number,
      cell_number,
      medicalaidname,
      medicalaidnumber,
      medical_history,
    } = this.state;

    return (
      <div className="container-fluid pt-4 px-4">
        <div className="row g-4">
          <div className="col-sm-6 col-xl-6">
            <div className="bg-light rounded h-100 p-4">
              <h2>Create Patient</h2>
              <Link className="btn btn-primary btn-sm" to={"/patient/list"}>
                <i className="far fa-address-card"></i> Patients
              </Link>
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Surname:</label>
                      <input
                        type="text"
                        name="surname"
                        value={surname}
                        onChange={this.handleInputChange}
                        className="form-control form-control-sm"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>First Name:</label>
                      <input
                        type="text"
                        name="firstname"
                        value={firstname}
                        onChange={this.handleInputChange}
                        className="form-control form-control-sm"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Date of Birth:</label>
                      <input
                        type="date"
                        name="date_of_birth"
                        value={date_of_birth}
                        onChange={this.handleInputChange}
                        className="form-control form-control-sm"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email:</label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={this.handleInputChange}
                        className="form-control form-control-sm"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Address:</label>
                  <input
                    type="text"
                    name="address"
                    value={address}
                    onChange={this.handleInputChange}
                    className="form-control form-control-sm"
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Work Number:</label>
                      <input
                        type="text"
                        name="work_number"
                        value={work_number}
                        onChange={this.handleInputChange}
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Home Number:</label>
                      <input
                        type="text"
                        name="home_number"
                        value={home_number}
                        onChange={this.handleInputChange}
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Cell Number:</label>
                      <input
                        type="text"
                        name="cell_number"
                        value={cell_number}
                        onChange={this.handleInputChange}
                        className="form-control form-control-sm"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Medical Aid Name:</label>
                      <input
                        type="text"
                        name="medicalaidname"
                        value={medicalaidname}
                        onChange={this.handleInputChange}
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Medical Aid Number:</label>
                      <input
                        type="text"
                        name="medicalaidnumber"
                        value={medicalaidnumber}
                        onChange={this.handleInputChange}
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Medical History:</label>
                  <textarea
                    name="medical_history"
                    value={medical_history}
                    onChange={this.handleInputChange}
                    className="form-control form-control-sm"
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary mt-2">Create Patient</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CreatePatient);
