import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <div className="container-fluid pt-4 px-4">
        <div className="bg-light rounded-top p-4">
          <div className="row">
            <div className="col-12 col-sm-6 text-center text-sm-start">
              &copy; <a href="#">MT Dental Services</a>, All Right Reserved.
            </div>
            <div className="col-12 col-sm-6 text-center text-sm-end">
               Designed by <a href="https://htmlcodex.com">YNB</a>
              <br />              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
