import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import { withRouter } from "../../common/with-router";
import apiUrl from "../../config";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class UpdateClinic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      address: "",
      contact_information: "",
      available_services: "",
      error: null,
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const id = this.props.params.id;
    axios
      .get(`${apiUrl}/api/clinics/${id}`)
      .then((response) => {
        const data = response.data;
        this.setState({
          name: data.name,
          address: data.address,
          contact_information: data.contact_information,
          available_services: data.available_services,
          isLoaded: true,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: error,
          isLoaded: true,
        });
      });
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const {
      name,
      address,
      contact_information,
      available_services,
    } = this.state;

    const postData = {
      name,
      address,
      contact_information,
      available_services,
    };

    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const config = {
        headers: { Authorization: `Bearer ${user.authorisation.token}` },
      };
      const id = this.props.params.id;
      const response = await axios.put(
        `${apiUrl}/api/clinics/${id}`,
        postData,
        config
      );
      this.props.router.navigate("/clinic/list");
      Swal.fire({
        icon: "success",
        title: "Clinic updated successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      console.log("Clinic updated:", response.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error updating clinic",
        text: error.response.data.message,
        confirmButtonText: "OK",
      });
      console.error("Error updating clinic:", error);
    }
  };

  render() {
    const {
      name,
      address,
      contact_information,
      available_services,
    } = this.state;
    return (
      <div className="container-fluid pt-4 px-4">
        <div className="row g-4">
          <div className="col-sm-6 col-xl-6">
            <div className="bg-light rounded h-100 p-4">
              <h2>Edit Clinic</h2>
              <Link className="btn btn-primary btn-sm" to={"/clinic/list"}>
                <i className="fas fa-clinic-medical"></i> Clinics
              </Link>

              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.handleInputChange}
                    className="form-control form-control-sm"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Address:</label>
                  <input
                    type="text"
                    name="address"
                    value={address}
                    onChange={this.handleInputChange}
                    className="form-control form-control-sm"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Contact Information:</label>
                  <input
                    type="text"
                    name="contact_information"
                    value={contact_information}
                    onChange={this.handleInputChange}
                    className="form-control form-control-sm"
                  />
                </div>
                <div className="form-group">
                  <label>Available Services:</label>
                  <input
                    type="text"
                    name="available_services"
                    value={available_services}
                    onChange={this.handleInputChange}
                    className="form-control form-control-sm"
                  />
                </div>
                <button type="submit" className="btn btn-primary mt-2">
                  Edit Clinic
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withParams(UpdateClinic));
