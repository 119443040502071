import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { withRouter } from '../../common/with-router';
import { Link } from "react-router-dom";
import apiUrl from '../../config.js';

class Add extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      address: '',
      contact_information: '',
      available_services: '',
      error: null,
      isLoaded: false,
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { name, address, contact_information, available_services } = this.state;

    const newClinic = {
      name,
      address,
      contact_information,
      available_services
    };

    try {
      const response = await axios.post(`${apiUrl}/api/clinics`, newClinic);
      this.props.router.navigate("/clinic/list");
      Swal.fire({
        icon: 'success',
        title: 'Clinic added successfully',
        showConfirmButton: false,
        timer: 1500
      });
      console.log('Clinic added:', response.data);
      this.setState({
        name: '',
        address: '',
        contact_information: '',
        available_services: '',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error adding clinic',
        text: error.message,
        confirmButtonText: 'OK'
      });
      console.error('Error adding clinic:', error);
    }
  };

  render() {
    const { name, address, contact_information, available_services } = this.state;

    return (
      <div className="container-fluid pt-4 px-4">
        <div className="row g-4">
          <div className="col-sm-6 col-xl-6">
            <div className="bg-light rounded h-100 p-4">
              <h6>Add Clinic</h6>
              <Link className="btn btn-primary btn-sm" to={"/clinic/list"}>
                <i className="fas fa-clinic-medical"></i> Clinics list
              </Link>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.handleInputChange}
                    className="form-control form-control-sm"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Address:</label>
                  <input
                    type="text"
                    name="address"
                    value={address}
                    onChange={this.handleInputChange}
                    className="form-control form-control-sm"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Contact Information:</label>
                  <input
                    type="text"
                    name="contact_information"
                    value={contact_information}
                    onChange={this.handleInputChange}
                    className="form-control form-control-sm"
                  />
                </div>
                <div className="form-group">
                  <label>Available Services:</label>
                  <textarea
                    name="available_services"
                    value={available_services}
                    onChange={this.handleInputChange}
                    className="form-control form-control-sm"
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-sm btn-primary mt-2">Add Clinic</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Add);
