import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import apiUrl from '../../config.js';
import { FaArrowLeft } from 'react-icons/fa';
class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleForgotPassword = async (event) => {
    event.preventDefault();
    const { email } = this.state;
    try {
      const response = await axios.post(
        `${apiUrl}/api/forgot-password`,
        { email }
      );
      console.log(response.data);
      swal(
        "Password Reset",
        "An email has been sent with instructions to reset your password.",
        "success"
      );
    } catch (error) {
      console.log(error);
      swal(
        "Something went wrong",
        "Failed to send reset email. Please try again later.",
        "error"
      );
    }
  };

  render() {
    return (
      <div className="container-xxl position-relative bg-white d-flex p-0">
        <div className="container-fluid">
          <div
            className="row h-100 align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
          >
            <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
              <div className="bg-light rounded p-4 p-sm-5 my-4 mx-3">
                <div className="d-flex flex-column align-items-center justify-content-center mb-3">
                  <h5 className="text-primary">
                    <img
                      src="https://mtds.yvesbizu.com/wp-content/uploads/2023/06/logo.png"
                      alt="MT Dental Services"
                      className="logo"
                      style={{ width: "200px", height: "auto" }}
                    />
                  </h5>
                  <h5>Forgot Password</h5>
                </div>

                <form onSubmit={this.handleForgotPassword}>
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingForgotEmail"
                      placeholder="name"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                    />
                    <label htmlFor="floatingForgotEmail">Email</label>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <Link to="/" className="ms-2">
                      <FaArrowLeft className="me-1" />
                      Back to login
                    </Link>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary py-3 w-100 mb-4"
                  >
                    Send Reset Email
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
