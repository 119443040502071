import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { withRouter } from '../../common/with-router';
import { Link } from 'react-router-dom';
import apiUrl from '../../config.js';

class Add extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      specialty: '',
      contact_information: '',
      availability: true,
      clinic_id: null,
      clinics: [],
      error: null,
      isLoaded: false,
      currentUser: JSON.parse(localStorage.getItem('user')).user,
    };
  }

  componentDidMount() {
    this.fetchClinics();
  }

  fetchClinics() {
    axios.get(`${apiUrl}/api/clinics`)
      .then(response => {
        this.setState({
          clinics: response.data,
          isLoaded: true,
        });
      })
      .catch(error => {
        this.setState({
          error: error,
          isLoaded: true
        });
      });
  }

  handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    const inputValue = name === 'availability' ? checked : value;
    this.setState({
      [name]: inputValue
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { name, email, specialty, contact_information, availability, clinic_id } = this.state;

    const newDoctor = {
      name,
      email,
      specialty,
      contact_information,
      availability,
      clinic_id
    };

    try {
      const response = await axios.post(`${apiUrl}/api/doctors`, newDoctor);
      this.props.router.navigate("/doctor/list");
      Swal.fire({
        icon: 'success',
        title: 'Doctor added successfully',
        showConfirmButton: false,
        timer: 1500
      });
      console.log('Doctor added:', response.data);
      this.setState({
        name: '',
        email: '',
        specialty: '',
        contact_information: '',
        availability: true,
        clinic_id: null
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error adding doctor',
        text: error.message,
        confirmButtonText: 'OK'
      });
      console.error('Error adding doctor:', error);
    }
  };

  render() {
    const { currentUser, name, email, specialty, contact_information, availability, clinic_id, clinics } = this.state;

    return (
      <div className="container-fluid pt-4 px-4">
        <div className="row g-4">
          <div className="col-sm-6 col-xl-6">
            <div className="bg-light rounded h-100 p-4">
              <h6>Add Doctor</h6>
              <Link
                className="btn btn-primary btn-sm"
                to={"/doctor/list"}
              >
                <i className="far fa-address-card"></i> Doctors
              </Link>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Specialty:</label>
                  <input
                    type="text"
                    name="specialty"
                    value={specialty}
                    onChange={this.handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Contact Information:</label>
                  <input
                    type="text"
                    name="contact_information"
                    value={contact_information}
                    onChange={this.handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Availability:</label>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="availability"
                      checked={availability}
                      onChange={this.handleInputChange}
                      className="form-check-input"
                    />
                    <label className="form-check-label">Available</label>
                  </div>
                </div>
                <div className="form-group">
                  <label>Clinic:</label>
                  <select
                    name="clinic_id"
                    value={clinic_id || ''}
                    onChange={this.handleInputChange}
                    className="form-control"
                  >
                    <option value="">Select a clinic</option>
                    {clinics.map(clinic => (
                      <option key={clinic.id} value={clinic.id}>{clinic.name}</option>
                    ))}
                  </select>
                </div>
                <button type="submit" className="btn btn-primary mt-2">Add Doctor</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Add);
