import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import { withRouter } from "../../common/with-router";
import apiUrl from "../../config";


function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class UpdateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      error: null,
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const id = this.props.params.id;
    axios
      .get(`${apiUrl}/api/users/${id}`)
      .then((response) => {
        const data = response.data;
        this.setState({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          role: data.role,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { firstName, lastName, email, role } = this.state;

    const postData = {
      firstName,
      lastName,
      email,
      role,
    };

    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const config = {
        headers: { Authorization: `Bearer ${user.authorisation.token}` },
      };
      const id = this.props.params.id;
      const response = await axios.put(
        `http://localhost:8000/api/users/${id}`,
        postData,
        config
      );
      this.props.router.navigate("/user/list");
      Swal.fire({
        icon: "success",
        title: "User updated successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      console.log("User updated:", response.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error updating user",
        text: error.response.data.message,
        confirmButtonText: "OK",
      });
      console.error("Error updating user:", error);
    }
  };

  render() {
    const { firstName, lastName, email, role } = this.state;
    return (
      <div className="container-fluid pt-4 px-4">
        <div className="row g-4">
          <div className="col-sm-6 col-xl-6">
            <div className="bg-light rounded h-100 p-4">
              <h2>Edit User</h2>
              <Link className="btn btn-primary btn-sm" to={"/user/list"}>
                <i className="far fa-address-card"></i> Users
              </Link>

              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>First Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    value={firstName}
                    onChange={this.handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Last Name:</label>
                  <input
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={this.handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Role:</label>
                  <select
                    name="role"
                    value={role}
                    onChange={this.handleInputChange}
                    className="form-control"
                    required
                  >
                    <option value="">Select a role</option>
                    <option value="0">Patient</option>
                    <option value="1">Receptionist</option>
                    <option value="2">System Administration</option>
                  </select>
                </div>
                <button type="submit" className="btn btn-primary mt-2">
                  Edit User
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withParams(UpdateUser));
