import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { withRouter } from '../../common/with-router';
import { Link } from 'react-router-dom';
import apiUrl from '../../config.js';

class Add extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appointment_date: '',
            appointment_time: '',
            clinic_id: '',
            doctor_id: '',
            patient_id: '',
            description: '',
            clinics: [],
            doctors: [],
            patients: [],
            payer_name: '',
            error: null,
            isLoaded: false,
            currentUser: JSON.parse(localStorage.getItem('user')).user,
        };
    }

    componentDidMount() {
        this.getData();

    }

    getData() {

        axios.all([
            axios.get(`${apiUrl}/api/clinics`),
            axios.get(`${apiUrl}/api/patients`)
        ])
            .then(axios.spread((clinicsRes, patientsRes) => {
                this.setState({
                    clinics: clinicsRes.data,
                    patients: patientsRes.data,
                    isLoaded: true,
                });
            }))
            .catch(error => {
                this.setState({
                    error: error,
                    isLoaded: true
                });
            });
    }

    handleClinicChange = (event) => {
        const clinicId = event.target.value;
        axios.get(`${apiUrl}/api/clinics-doctors/${clinicId}`)
            .then(doctorsRes => {
                this.setState({
                    clinic_id: clinicId,
                    doctors: doctorsRes.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleSubmit = async (event) => {
        event.preventDefault();

        const { currentUser, payer_name, appointment_date, description, appointment_time, clinic_id, doctor_id, patient_id } = this.state;

        const postData = currentUser.role === 1 || currentUser.role === 2
            ? { appointment_date, appointment_time, clinic_id, description, payer_name, doctor_id, patient_id }
            : { appointment_date, appointment_time: "00:00", payer_name, description, clinic_id: 1, doctor_id: 1, patient_id: currentUser.id, user_id: currentUser.id };

        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const config = {
                headers: { Authorization: `Bearer ${user.authorisation.token}` }
            };
            const response = await axios.post(`${apiUrl}/api/appointments`, postData, config);
            this.props.router.navigate("/appointment/list");
            Swal.fire({
                icon: 'success',
                title: 'Appointment saved successfully',
                showConfirmButton: false,
                timer: 1500
            });
            console.log('Appointment saved:', response.data);
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'An error occurred while saving the appointment. Please try again later.';

            Swal.fire({
                icon: 'error',
                title: 'Error saving appointment',
                text: errorMessage,
                confirmButtonText: 'OK'
            });

            console.error('Error saving appointment:', errorMessage);
        }
    }


    render() {
        const { currentUser, payer_name, clinics, doctors, patients, appointment_date, appointment_time, clinic_id, doctor_id, patient_id, description } = this.state;
        return (
            <div className="container-fluid pt-4 px-4">
                <div className="row g-4">
                    <div className="col-sm-6 col-xl-6">
                        <div className="bg-light rounded h-100 p-4">
                            <h6>Add Appointment</h6>
                            <Link
                                className="btn btn-primary btn-sm"
                                to={"/appointment/list"}
                            >
                                <i className="far fa-address-card"></i> Appointments
                            </Link>
                            {currentUser.role === 1 || currentUser.role === 2?
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label>Date:</label>
                                        <input type="date" name="appointment_date" value={appointment_date} onChange={this.handleInputChange} className="form-control" required />
                                    </div>
                                    <div className="form-group">
                                        <label>Time:</label>
                                        <input type="time" name="appointment_time" value={appointment_time} onChange={this.handleInputChange} className="form-control" required />
                                    </div>
                                    <div className="form-group">
                                        <label>Clinic:</label>
                                        <select name="clinic_id" value={clinic_id} onChange={this.handleClinicChange} className="form-control" required>
                                            <option value="">Select a clinic</option>
                                            {clinics.map(clinic => (
                                                <option key={clinic.id} value={clinic.id}>{clinic.name} {clinic.address}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Doctor:</label>
                                        <select name="doctor_id" value={doctor_id} onChange={this.handleInputChange} className="form-control" required>
                                            <option value="">Select a doctor</option>
                                            {doctors.map(doctor => (
                                                <option key={doctor.id} value={doctor.id}>{doctor.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Patient:</label>
                                        <select name="patient_id" value={patient_id} onChange={this.handleInputChange} className="form-control" required>
                                            <option value="">Select a patient</option>
                                            {patients.map(patient => (
                                                <option key={patient.id} value={patient.id}>{patient.firstname} {patient.surname}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label>Payer name:</label>
                                        <input type="text" name="payer_name" value={payer_name} onChange={this.handleInputChange} className="form-control" required />
                                    </div>
                                    <div className="form-group">
                                        <label>Appointment details:</label>
                                        <textarea name="description" value={description} onChange={this.handleInputChange} className="form-control"></textarea>

                                    </div>
                                    <button type="submit" className="btn btn-primary mt-2">Save Appointment</button>
                                </form>
                                :

                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label>Date:</label>
                                        <input type="date" name="appointment_date" value={appointment_date} onChange={this.handleInputChange} className="form-control" required />
                                    </div>
                                    <div className="form-group">
                                        <label>Payer name:</label>
                                        <input type="text" name="payer_name" value={payer_name} onChange={this.handleInputChange} className="form-control" required />
                                    </div>
                                    <div className="form-group">
                                        <label>Appointment details:</label>
                                        <textarea name="description" value={description} onChange={this.handleInputChange} className="form-control"></textarea>

                                    </div>
                                    <button type="submit" className="btn btn-primary mt-2">Save Appointment</button>
                                </form>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Add);
