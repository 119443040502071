import React, { Component } from 'react';
import axios from 'axios';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      error: null
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    const config = {
      headers: { Authorization: `Bearer ${user.authorisation.token}` }
    };
    axios.get('http://localhost:8000/api/notifications', config)
      .then(response => {
        this.setState({ notifications: response.data.notifications });
      })
      .catch(error => {
        this.setState({ error: error.message });
      });
  }

  render() {
    const { notifications, error } = this.state;
    return (
      <div>
        <h2>Notifications</h2>
        {notifications.map(notification => (
          <div key={notification.id}>
            <p>{notification.data.patient_name} has an appointment with {notification.data.doctor_name} on {notification.data.appointment_date} at {notification.data.appointment_time}</p>
          </div>
        ))}
        {error && <p>Error: {error}</p>}
      </div>
    );
  }
}

export default Notifications;
