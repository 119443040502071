import React, { Component } from 'react';
import axios from "axios";
import { withRouter } from '../../common/with-router';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import apiUrl from '../../config.js';
import { FaArrowLeft } from 'react-icons/fa';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            rememberMe: false,
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    };

    handleSubmit = async (event) => {

        event.preventDefault();
        const { email, password } = this.state;
        try {
            const response = await axios.post(`${apiUrl}/api/login`, { email, password });
            const { token } = response.data;
            localStorage.setItem("user", JSON.stringify(response.data));
            console.log("user", JSON.stringify(response.data)); // Display token in console            
            this.props.router.navigate("/");
            window.location.reload();
            swal("Good job!", "Login Sucessfully!", "success");

        } catch (error) {
            console.log(error);
            // Display error message to user
            swal("Something is wrong!", "Login unsuccessfull!", "warning");
        }
    };

    render() {
        return (
            <div className="container-xxl position-relative bg-white d-flex p-0">
                <div className="container-fluid">
                    <div className="row h-100 align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
                        <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                            <div className="bg-light rounded p-4 p-sm-5 my-4 mx-3">
                                <div className="d-flex flex-column align-items-center justify-content-center mb-3">
                                    <Link to="/">
                                        <img
                                            src="https://mtds.yvesbizu.com/wp-content/uploads/2023/06/logo.png"
                                            alt="MT Dental Services"
                                            className="logo"
                                            style={{ width: "200px", height: "auto" }}
                                        />
                                    </Link>
                                    <h5 className="text-primary">Sign In</h5>
                                </div>

                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="floatingInput"
                                            placeholder="name"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleInputChange}
                                        />
                                        <label htmlFor="floatingInput">Email</label>
                                    </div>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="floatingPassword"
                                            placeholder="Password"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleInputChange}
                                        />
                                        <label htmlFor="floatingPassword">Password</label>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="exampleCheck1"
                                                name="rememberMe"
                                                checked={this.state.rememberMe}
                                                onChange={this.handleInputChange}
                                            />
                                            <label className="form-check-label" htmlFor="exampleCheck1">
                                                Check me out
                                            </label>
                                        </div>

                                        <Link to={'/forgot-password'} className="ms-2"> Forgot Password </Link>
                                    </div>
                                    <button type="submit" className="btn btn-primary py-3 w-100 mb-4">
                                        Sign In
                                    </button>
                                    <br />
                                    <Link to="https://www.mtdental.co.sz" className="ms-2">
                                        <FaArrowLeft className="me-1" />
                                        Back to website
                                    </Link>
                                    <div hidden className="text-center mb-3">
                                        <span>Don't have an account?</span>
                                        <Link to={'/register'} className="ms-2"> Register </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Login);
