import React from 'react';
import { Link } from 'react-router-dom';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem('user')).user,
    };
  }

  render() {
    const { user } = this.state;
    const isAdminOrReceptionist = user.role === 1 || user.role === 2;

    return (
      <div className="sidebar pe-4 pb-3">
        <nav className="navbar bg-light navbar-light">
          <Link to="/" className="navbar-brand mx-4 mb-3">
            <h5 className="text-primary">
              <i className="fa fa-hashtag me-2"></i>MT Dental Services
            </h5>
          </Link>
          <div className="d-flex align-items-center ms-4 mb-4">
            <div className="position-relative">
              <img
                className="rounded-circle"
                src="/img/user.png"
                alt=""
                style={{ width: '40px', height: '40px' }}
              />
              <div className="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"></div>
            </div>
            <div className="ms-3">
              <h6 className="mb-0">
                {user.firstName} {user.lastName}{' '}
              </h6>
              {user.role === 1 ? (
                <span>Receptionist</span>
              ) : user.role === 2 ? (
                <span>Administrator</span>
              ) : (
                <span>Patient</span>
              )}
            </div>
          </div>

          {isAdminOrReceptionist ? (
            <div className="navbar-nav w-100">
              <Link to="/" className="nav-item nav-link active">
                <i className="fa fa-tachometer-alt me-2"></i>Dashboard
              </Link>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <i className="fas fa-calendar-alt me-2"></i>Appointment
                </Link>
                <div className="dropdown-menu bg-transparent border-0">
                  <Link to="/appointment/add" className="dropdown-item">
                    Add new
                  </Link>
                  <Link to="/appointment/list" className="dropdown-item">
                    List
                  </Link>
                  <Link to="/appointment/report" className="dropdown-item">
                    Report
                  </Link>

                </div>
              </div>

              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <i className="fas fa-clinic-medical me-2"></i>Clinic
                </Link>
                <div className="dropdown-menu bg-transparent border-0">
                  <Link to="/clinic/add" className="dropdown-item">
                    Add new
                  </Link>
                  <Link to="/clinic/list" className="dropdown-item">
                    List
                  </Link>
                </div>
              </div>

              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <i className="fas fa-user-md me-2"></i>Doctor
                </Link>
                <div className="dropdown-menu bg-transparent border-0">
                  <Link to="/doctor/add" className="dropdown-item">
                    Add new
                  </Link>
                  <Link to="/doctor/list" className="dropdown-item">
                    List
                  </Link>
                </div>
              </div>

              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <i className="fas fa-user-injured me-2"></i>Patient
                </Link>
                <div className="dropdown-menu bg-transparent border-0">
                  <Link to="/patient/add" className="dropdown-item">
                    Add new
                  </Link>
                  <Link to="/patient/list" className="dropdown-item">
                    List
                  </Link>
                </div>
              </div>

              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <i className="fas fa-user-friends me-2"></i> User
                </Link>
                <div className="dropdown-menu bg-transparent border-0">
                  <Link to="/user/add" className="dropdown-item">
                    Add new
                  </Link>
                  <Link to="/user/list" className="dropdown-item">
                    List
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="navbar-nav w-100">
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <i className="fas fa-calendar-alt me-2"></i>Appointment
                </Link>
                <div className="dropdown-menu bg-transparent border-0">
                  <Link to="/appointment/add" className="dropdown-item">
                    Add new
                  </Link>
                  <Link to="/appointment/list" className="dropdown-item">
                    List
                  </Link>
                </div>
              </div>
            </div>
          )}
        </nav>
      </div>
    );
  }
}

export default Sidebar;
