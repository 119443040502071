import React, { Component } from 'react';
import axios from "axios";
import { withRouter } from '../../common/with-router';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import apiUrl from '../../config.js';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const { firstName, lastName, email, password } = this.state;
        try {
            const response = await axios.post(`${apiUrl}/api/register`, { firstName, lastName, email, password });
            localStorage.setItem("user", JSON.stringify(response.data));
            console.log("user", JSON.stringify(response.data)); // Display user data in console            
            this.props.router.navigate("/");
            window.location.reload();
            swal("Good job!", "Registration Sucessfull!", "success");

        } catch (error) {
            console.log(error);
            // Display error message to user
            swal("Something is wrong!", "Registration Unsucessfull!", "warning");
        }
    };

    render() {
        return (
            <div className="container-xxl position-relative bg-white d-flex p-0">
                <div className="container-fluid">
                    <div className="row h-100 align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
                        <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                            <div className="bg-light rounded p-4 p-sm-5 my-4 mx-3">
                                <div className="d-flex flex-column align-items-center justify-content-center mb-3">
                                    <Link to="https://mtds.yvesbizu.com">
                                        <img
                                            src="https://mtds.yvesbizu.com/wp-content/uploads/2023/06/logo.png"
                                            alt="MT Dental Services"
                                            className="logo"
                                            style={{ width: "200px", height: "auto" }}
                                        />
                                    </Link>
                                    <h5 className="text-primary">Register</h5>
                                </div>

                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="floatingFirstName"
                                            placeholder="First Name"
                                            name="firstName"
                                            value={this.state.firstName}
                                            onChange={this.handleInputChange}
                                        />
                                        <label htmlFor="floatingFirstName">First Name</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="floatingLastName"
                                            placeholder="Last Name"
                                            name="lastName"
                                            value={this.state.lastName}
                                            onChange={this.handleInputChange}
                                        />
                                        <label htmlFor="floatingLastName">Last Name</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="floatingEmail"
                                            placeholder="Email"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleInputChange}
                                        />
                                        <label htmlFor="floatingEmail">Email</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="floatingPassword"
                                            placeholder="Password"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleInputChange}
                                        />
                                        <label for="floatingPassword"
                                        >
                                            Password
                                        </label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="floatingConfirmPassword"
                                            placeholder="Confirm Password"
                                            name="confirmPassword"
                                            value={this.state.confirmPassword}
                                            onChange={this.handleInputChange}
                                        />
                                        <label
                                            htmlFor="floatingConfirmPassword"
                                        >
                                            Confirm Password
                                        </label>
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-lg btn-primary mt-3 w-100"
                                    >
                                        Register
                                    </button>
                                </form>
                            </div>
                            <div className="text-center mb-3">
                                <span>Already have an account?</span>
                                <Link to="/login" className="ms-2">
                                    Login
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Register);
