import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { withRouter } from '../../common/with-router';
import { Link } from 'react-router-dom';
import apiUrl from '../../config';

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      role: 0,
      error: null,
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { firstName, lastName, email, role } = this.state;

    const newUser = {
      firstName,
      lastName,
      email,
      role
    };

    try {
      const response = await axios.post(`${apiUrl}/api/users`, newUser);
      this.props.router.navigate("/user/list");
      Swal.fire({
        icon: 'success',
        title: 'User added successfully',
        showConfirmButton: false,
        timer: 1500
      });
      console.log('User added:', response.data);
      this.setState({
        firstName: '',
        lastName: '',
        email: '',
        role: 'patient'
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error adding user',
        text: error.message,
        confirmButtonText: 'OK'
      });
      console.error('Error adding user:', error);
    }
  };

  render() {
    const { firstName, lastName, email, role } = this.state;

    return (
      <div className="container pt-4 px-4">
        <div className="row g-4">
          <div className="col-sm-6 col-xl-6">
            <div className="bg-light rounded h-100 p-4">
              <h6>Add User</h6>
              <Link
                className="btn btn-primary btn-sm"
                to={"/user/list"}
              >
                <i className="far fa-address-card"></i> Users
              </Link>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>First Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    value={firstName}
                    onChange={this.handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Last Name:</label>
                  <input
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={this.handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>User Type:</label>
                  <select
                    name="role"
                    value={role}
                    onChange={this.handleInputChange}
                    className="form-control"
                  >
                    <option value={0}>Patient</option>
                    <option value={1}>Receptionist</option>
                    <option value={2}>System Admin</option>
                  </select>
                </div>
                <button type="submit" className="btn btn-primary mt-2">Add User</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddUser);
