import React from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import moment from 'moment';
import apiUrl from '../../config.js';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            notifications: [],
            error: null,
            rememberMe: false,
            user: JSON.parse(localStorage.getItem('user')).user,
        };
    }

    componentDidMount() {
        this.loadNotifications();
    }

    loadNotifications() {
        const user = JSON.parse(localStorage.getItem('user'));
        const config = {
            headers: { Authorization: `Bearer ${user.authorisation.token}` }
        };
        axios.get(`${apiUrl}/api/notifications`, config)
            .then(response => {
                this.setState({ notifications: response.data.notifications });
            })
            .catch(error => {
                this.setState({ error: error.message });
            });
    }


    logout() {
        localStorage.removeItem("user");
        window.location.href = '/login';
        swal("Good job!", "Logout Sucessfully!", "success");
    }

    render() {
        const { user } = this.state;
        const { notifications, error } = this.state;
        return (
            <nav className="navbar navbar-expand bg-light navbar-light sticky-top px-4 py-0">
                <a href="index.html" className="navbar-brand d-flex d-lg-none me-4">
                    <h2 className="text-primary mb-0"><i className="fa fa-hashtag"></i></h2>
                </a>
                <a href="#" className="sidebar-toggler flex-shrink-0">
                    <i className="fa fa-bars"></i>
                </a>
                <form className="d-none d-md-flex ms-4">
                    <input className="form-control border-0" type="search" placeholder="Search" />
                </form>
                <div className="navbar-nav align-items-center ms-auto">
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                            <i className="fa fa-bell me-lg-2"></i>
                            <span className="d-snone d-lg-inline-flex">Notification</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0" style={{ maxHeight: '300px', overflowY: 'auto' }}>

                            {notifications.map(notification => (
                                <div key={notification.id}>
                                    <a href="#" className="dropdown-item">
                                        <h6 className="fw-normal mb-0">{notification.data['data']}</h6>
                                        <small>{moment(notification.created_at).fromNow()}</small>
                                    </a>
                                </div>
                            ))}
                            {error && <p>Error: {error}</p>}
                            <hr className="dropdown-divider" />
                            <a href="#" className="dropdown-item text-center">See all notifications</a>
                        </div>
                    </div>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                            <img className="rounded-circle me-lg-2" src="/img/user.png" alt="" style={{ width: "40px", height: "40px" }} />
                            <span className="d-none d-lg-inline-flex">{user.firstName} {user.lastName}</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
                            <a href="#" className="dropdown-item">My Profile</a>
                            <a href="#" className="dropdown-item">Settings</a>
                            <a onClick={this.logout} className="dropdown-item">Log Out</a>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Header;
