import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doctors: [],
      isLoading: true,
      error: null,
      currentPage: 1,
      itemsPerPage: 10,
    };
  }

  componentDidMount() {
    this.getDoctors();
  }

  getDoctors = () => {
    axios
      .get("http://localhost:8000/api/doctors")
      .then((response) => {
        this.setState({ doctors: response.data, isLoading: false });
      })
      .catch((error) => {
        this.setState({
          error: error,
          isLoading: false,
        });
      });
  };

  handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const user = JSON.parse(localStorage.getItem("user"));
        const config = {
          headers: { Authorization: `Bearer ${user.authorisation.token}` },
        };
        axios
          .delete(`http://localhost:8000/api/doctors/${id}`, config)
          .then((response) => {
            swal(
              "Success",
              "The item has been deleted successfully.",
              "success"
            );
            // update the data after delete
            const updatedData = this.state.doctors.filter(
              (doctor) => doctor.id !== id
            );
            this.setState({ doctors: updatedData });
          })
          .catch((error) => {
            console.log(error);
            const errorMessage = error.response ? error.response.data.message : 'An error occurred while deleting the item. Please try again later.';
            swal(
              "Error",
              errorMessage,
              "error"
            );
          });
      } else {
        swal("Cancelled", "The item was not deleted.", "info");
      }
    });
  };

  paginate = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  render() {
    const { isLoading, error, currentPage, itemsPerPage } = this.state;

    // Logic for displaying items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = this.state.doctors.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    if (isLoading) {
      return <p>Loading...</p>;
    }

    if (error) {
      return <p>Error: {error.message}</p>;
    }

    return (
      <div>
        <div className="container-fluid pt-4 px-4">
          <div className="row g-4">
            <div className="col-sm-12 col-xl-12">
              <div className="bg-light rounded h-100 p-4">
                <h6 className="mb-4">List of doctors</h6>
                <Link
                  className="btn btn-primary btn-sm"
                  to={"/doctor/add"}
                >
                  <i className="far fa-address-card"></i> Add Doctors
                </Link>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Action</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Specialty</th>
                      <th>Contact Information</th>
                      <th>Availability</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((doctor) => (
                      <tr key={doctor.id}>
                        <td scope="row">
                          <Link
                            title="Edit"
                            to={`/doctor/edit/${doctor.id}`}
                          >
                            <i className="far fa-edit"></i>
                          </Link>
                          &nbsp;
                          <a
                            className="text-danger"
                            title="Delete"
                            onClick={() => this.handleDelete(doctor.id)}
                          >
                            <i className="far fa-trash-alt"></i>
                          </a>
                        </td>
                        <td>{doctor.id}</td>
                        <td>{doctor.name}</td>
                        <td>{doctor.email}</td>
                        <td>{doctor.specialty}</td>
                        <td>{doctor.contact_information}</td>
                        <td>{doctor.availability ? "Available" : "Not Available"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <nav>
                  <ul className="pagination">
                    {Array.from({
                      length: Math.ceil(
                        this.state.doctors.length / itemsPerPage
                      ),
                    }).map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          onClick={() => this.paginate(index + 1)}
                        >
                          {index + 1}
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default List;
