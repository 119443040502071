import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import { withRouter } from "../../common/with-router";
import apiUrl from "../../config";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class UpdateDoctor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      specialty: "",
      contact_information: "",
      clinic_id: "",
      availability: false,
      clinics: [],
      error: null,
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.getData();
    this.loadData();
  }

  loadData() {
    const id = this.props.params.id;
    axios
      .get(`${apiUrl}/api/doctors/${id}`)
      .then((response) => {
        const data = response.data;
        this.setState({
          name: data.name,
          email: data.email,
          specialty: data.specialty,
          contact_information: data.contact_information,
          clinic_id: data.clinic_id,
          availability: data.availability,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getData() {
    axios
      .get(`${apiUrl}/api/clinics`)
      .then((response) => {
        this.setState({
          clinics: response.data,
          isLoaded: true,
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
          isLoaded: true,
        });
      });
  }

  handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    this.setState({
      [name]: inputValue,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const {
      name,
      email,
      specialty,
      contact_information,
      clinic_id,
      availability,
    } = this.state;

    const postData = {
      name,
      email,
      specialty,
      contact_information,
      clinic_id,
      availability,
    };

    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const config = {
        headers: { Authorization: `Bearer ${user.authorisation.token}` },
      };
      const id = this.props.params.id;
      const response = await axios.put(
        `${apiUrl}/api/doctors/${id}`,
        postData,
        config
      );
      this.props.router.navigate("/doctor/list");
      Swal.fire({
        icon: "success",
        title: "Doctor updated successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      console.log("Doctor updated:", response.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error updating doctor",
        text: error.response.data.message,
        confirmButtonText: "OK",
      });
      console.error("Error updating doctor:", error);
    }
  };

  render() {
    const {
      name,
      email,
      specialty,
      contact_information,
      clinic_id,
      availability,
      clinics,
    } = this.state;
    return (
      <div className="container-fluid pt-4 px-4">
        <div className="row g-4">
          <div className="col-sm-6 col-xl-6">
            <div className="bg-light rounded h-100 p-4">
              <h2>Edit Doctor</h2>
              <Link className="btn btn-primary btn-sm" to={"/doctor/list"}>
                <i className="far fa-address-card"></i> Doctors
              </Link>

              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Specialty:</label>
                  <input
                    type="text"
                    name="specialty"
                    value={specialty}
                    onChange={this.handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Contact Information:</label>
                  <textarea
                    name="contact_information"
                    value={contact_information}
                    onChange={this.handleInputChange}
                    className="form-control"
                  ></textarea>
                </div>
                <div className="form-group">
                  <label>Clinic:</label>
                  <select
                    name="clinic_id"
                    value={clinic_id}
                    onChange={this.handleInputChange}
                    className="form-control"
                    required
                  >
                    <option value="">Select a clinic</option>
                    {clinics.map((clinic) => (
                      <option
                        key={clinic.id}
                        value={clinic.id}
                        defaultValue={clinic.id === clinic_id}
                      >
                        {clinic.name} {clinic.address}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Availability:</label>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="availability"
                      checked={availability}
                      onChange={this.handleInputChange}
                      className="form-check-input"
                    />
                    <label className="form-check-label">Available</label>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mt-2">
                  Edit Doctor
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withParams(UpdateDoctor));
