import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Home from "./components/Home";
import Sidebar from "./components/sidebar/Sidebar";
import Footer from "./components/footer/Footer";

// AUTHENTICATION SERVICES
import AuthService from "./services/auth.service";
import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from './components/auth/ResetPassword';
// import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import Notifications from "./components/Notifications";


import List from "./components/appointments/List";
import Add from "./components/appointments/Add";
import Register from "./components/auth/Register";
import Edit from "./components/appointments/Edit";

import DoctorList from "./components/doctor/List";
import DoctorAdd from "./components/doctor/Add";
import DoctorEdit from "./components/doctor/Edit";

import ClinicList from "./components/clinic/List";
import ClinicAdd from "./components/clinic/Add";
import ClinicEdit from "./components/clinic/Edit";

import DepartmentList from "./components/department/List";
import DepartmentAdd from "./components/department/Add";
import DepartmentEdit from "./components/department/Edit";

import PaymentList from "./components/payment/List";
import PaymentAdd from "./components/payment/Add";
import PaymentEdit from "./components/payment/Edit";

import MedicalRecordList from "./components/medical-record/List";
import MedicalRecordAdd from "./components/medical-record/Add";
import MedicalRecordEdit from "./components/medical-record/Edit";

import MedicalServiceList from "./components/medical-service/List";
import MedicalServiceAdd from "./components/medical-service/Add";
import MedicalServiceEdit from "./components/medical-service/Edit";

import MedicalAidList from "./components/medical-aid/List";
import MedicalAidAdd from "./components/medical-aid/Add";
import MedicalAidEdit from "./components/medical-aid/Edit";

import UserAdd from "./components/user/Add";
import UserEdit from "./components/user/Edit";
import UserList from "./components/user/List";

import PatientList from "./components/patient/List";
import PatientAdd from "./components/patient/Add";
import PatientEdit from "./components/patient/Edit";
import Report from "./components/appointments/Report";

class App extends Component {
  // ...

  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
      });
      //console.log(user.role);
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;
    return (
      <Router>
        {currentUser ? (
          <div className="container-xxl position-relative bg-white d-flex p-0">
            <Sidebar />
            <div className="content">
              <Header />
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/appointment/add" element={<Add />} />
                <Route exact path="/appointment/list" element={<List />} />
                <Route exact path="/appointment/report" element={<Report />} />               
                <Route exact path="/appointment/edit/:id" element={<Edit />} />
                <Route exact path="/clinic/add" element={<ClinicAdd />} />
                <Route exact path="/clinic/list" element={<ClinicList />} />
                <Route exact path="/clinic/edit/:id" element={<ClinicEdit />} />
                <Route exact path="/department/add" element={<DepartmentAdd />} />
                <Route exact path="/department/list" element={<DepartmentList />} />
                <Route exact path="/department/edit/:id" element={<DepartmentEdit />} />
                <Route exact path="/doctor/add" element={<DoctorAdd />} />
                <Route exact path="/doctor/list" element={<DoctorList />} />
                <Route exact path="/doctor/edit/:id" element={<DoctorEdit />} />
                <Route exact path="/payment/add" element={<PaymentAdd />} />
                <Route exact path="/payment/list" element={<PaymentList />} />
                <Route exact path="/payment/edit/:id" element={<PaymentEdit />} />
                <Route exact path="/medical-record/add" element={<MedicalRecordAdd />} />
                <Route exact path="/medical-record/list" element={<MedicalRecordList />} />
                <Route exact path="/medical-record/edit/:id" element={<MedicalRecordEdit />} />
                <Route exact path="/medical-service/add" element={<MedicalServiceAdd />} />
                <Route exact path="/medical-service/list" element={<MedicalServiceList />} />
                <Route exact path="/medical-service/edit/:id" element={<MedicalServiceEdit />} />
                <Route exact path="/medical-aid/add" element={<MedicalAidAdd />} />
                <Route exact path="/medical-aid/list" element={<MedicalAidList />} />
                <Route exact path="/medical-aid/edit/:id" element={<MedicalAidEdit />} />
                
                <Route exact path="/user/add" element={<UserAdd />} />
                <Route exact path="/user/list" element={<UserList />} />
                <Route exact path="/user/edit/:id" element={<UserEdit />} />

                <Route exact path="/patient/add" element={<PatientAdd />} />
                <Route exact path="/patient/list" element={<PatientList />} />
                <Route exact path="/patient/edit/:id" element={<PatientEdit />} />
                <Route exact path="/notifications" element={<Notifications />} />
              </Routes>
              <Footer />
            </div>
          </div>
        ) : (
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password/:email/:token" element={<ResetPassword />} />

  
            <Route exact path="/register" element={<Register />} />
          </Routes>
        )}
      </Router>
    );
  }
}

export default App;
