import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";
import { withRouter } from "../../common/with-router";
import { Link, useParams } from "react-router-dom";
import apiUrl from '../../config.js';

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      confirmPassword: "",
      isLoading: false,
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  handleResetPassword = async (event) => {
    event.preventDefault();

    const { password, confirmPassword } = this.state;
    const { token } = this.props.params;

    if (password !== confirmPassword) {
      swal(
        "Password Mismatch",
        "Passwords do not match. Please try again.",
        "error"
      );
      return;
    }

    try {
      this.setState({ isLoading: true });

      const response = await axios.post(
        `${apiUrl}/api/reset-password`,
        {
          email: this.props.params.email, // Access email from params directly
          password,
          password_confirmation: confirmPassword,
          token,
        }
      );

      console.log("Email:", this.props.params.email);
      console.log("Password:", password);
      console.log("Token:", token);

      console.log(response.data);
      swal(
        "Password Reset",
        "Your password has been reset successfully.",
        "success"
      );
      this.props.router.navigate("/login");
      // Redirect or perform any other necessary actions after successful password reset
    } catch (error) {
      console.log("Email:", this.props.params.email);
      console.log("Password:", password);
      console.log("Token:", token);
      console.log(error);
      swal(
        "Something went wrong",
        "Failed to reset your password. Please try again later.",
        "error"
      );
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { password, confirmPassword, isLoading } = this.state;

    return (
      <div className="container-xxl position-relative bg-white d-flex p-0">
        <div className="container-fluid">
          <div
            className="row h-100 align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
          >
            <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
              <div className="bg-light rounded p-4 p-sm-5 my-4 mx-3">
                <div className="d-flex flex-column align-items-center justify-content-center mb-3">
                  <Link to="/login">
                    <img
                      src="https://mtds.yvesbizu.com/wp-content/uploads/2023/06/logo.png"
                      alt="MT Dental Services"
                      className="logo"
                      style={{ width: "200px", height: "auto" }}
                    />
                  </Link>
                  <h5 className="text-primary">Reset Password</h5>
                </div>

                <form onSubmit={this.handleResetPassword}>
                  <div className="form-floating mb-3">
                    <input
                      type="password"
                      className="form-control"
                      id="floatingNewPassword"
                      placeholder="New Password"
                      name="password"
                      value={password}
                      onChange={this.handleInputChange}
                    />
                    <label htmlFor="floatingNewPassword">New Password</label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="password"
                      className="form-control"
                      id="floatingConfirmPassword"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={this.handleInputChange}
                    />
                    <label htmlFor="floatingConfirmPassword">
                      Confirm Password
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary py-3 w-100 mb-4"
                    disabled={isLoading}
                  >
                    {isLoading ? "Resetting Password..." : "Reset Password"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withParams(ResetPassword));
